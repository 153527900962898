<template>
<section id="about" class="about-area pb-60">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-xl-5 col-lg-6">
                    <div class="testimonial-left-content mt-45">
                        <h1 class="title"> {{ $t('Contents.AboutUs') }} </h1>
                        <div class="c-line"></div>
                        <!-- <h4 class="title">What Clients Says <br> About Us</h4> -->
                        <ul class="testimonial-line">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <p class="text pb-2">{{ $t('Contents.AboutUsDetails') }}</p>
                        <p class="text">{{ $t('Contents.AboutUsDetails2') }}</p>
                    </div>
                    <!-- testimonial left content -->
                </div>
                <div class="col-lg-6">
                    <div class="testimonial-right-content mt-50">
                         <img type="image/webp" class="about-image" src="assets/images/about-jmelectric.webp" alt="about-jmelectric" >
<!--                        <v-lazy-image src="assets/images/about-jmelectric.webp" alt="about-jmelectric" />-->
                    </div>
                    <!-- testimonial right content -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>

</template>

<script>
// import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    // VLazyImage
  }
};
</script>


<style scoped>
    .about-image{
        border-radius: 1%;
        box-shadow: 6px 13px 23px rgb(0 0 0 / 25%);
    }

    .testimonial-area{
        background-color: white;
    }

    .text{
        text-align: justify;
    }
</style>